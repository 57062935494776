import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Subhead, Button } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { BiChevronRight } from "react-icons/bi";
export default function Reduced_hiring(props) {
    return (
        <Section style={{ background: "radial-gradient(88.77% 251.23% at 12.81% -80.93%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#FFF", transform:"skewY(-5deg)",marginBottom:"7%"}} >
            <Container  style={{ transform:"skewY(5deg)"}} >
                <Flex gap={4} variant="responsive">
                <Box>
                        <Subhead>
                        Reduced hiring and training costs for industry
                        </Subhead>
                        <Text>
                        For any Industy hiring of a good candidates takes substantial time from finding CV and ensuring sufficient industry exposure. In addition, recruitment involves lot of cost from payment to recuritment agency for providing CVs and after hiring, substantial cost invovled in training them. Further, their productivity for couple of month (say 2 to 3month) would very low and theirby the salary cost goes with out much results. However, with skill-elevator collobration, Industy need not worry about it.  With just a click of button, Industry can downloan 'Verified and Trained CV's at free of cost. Further, these candidates are already trained and experienced (average 1 to 3 years of virutal industry experienced) , no separate time spent for fresher training is not required. 
                        </Text>
                        <Button>Get faster hiring with us at no cost <BiChevronRight/></Button>
                    </Box>
                    <Box>
                    <Text >
                            <StaticImage  src='../../../images/corporate_images/corporate_img4.png' alt="corporate_img4" />
                        </Text>
                    </Box>
                   
                </Flex>
               
            </Container>
        </Section>
    )
}
