import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo";
import AssociatesNavList from "../components/associates/nav_items";
import Corporate_hero from "../components/associates/corporate/corporate_hero";
import Unique_platform from "../components/associates/corporate/unique_platform";
import Createmore_skill from "../components/associates/corporate/createmore_skill";
import Skilled_trined from "../components/associates/corporate/skilled_trined";
import Reduced_hiring from "../components/associates/corporate/reduced_hiring";
import ContactUs from '../components/contact-us.js'

const Associates = () => (
  <Layout navName="Associates">
    <Seo title="Corporate" />
    <AssociatesNavList navName="Corporate" />
    <Corporate_hero />
    <Unique_platform />
    <Createmore_skill />
    <Skilled_trined />
    <Reduced_hiring/>
    <ContactUs/>
  </Layout>
)

export default Associates
