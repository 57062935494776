import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
export default function Createmore_skill(props) {
    return (
        <Section style={{ background: "radial-gradient(88.77% 251.23% at 12.81% -80.93%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#FFF", transform:"skewY(-5deg)"}} >
            <Container style={{ transform:"skewY(5deg)"}} >
                <Flex gap={4} variant="responsive">
                <Box>
                        <Subhead>
                        Create more skilled graduates from college

                        </Subhead>
                        <Text>
                        Skill-Eleavtor with its wide range of business laboratory modules and five different roles based simulated modules, helps student get thorough grip on the Industy work which inturn makes them more confident about their career. Studenc upon successful competion of work modules in the virutual company, Platform gives them experience certificate and automatically makes a Verified and Trined CV' along with the Score card which authenticate their depth of learning and experience the Accounting, Tax and Finance Field.
                        </Text>
                    </Box>
                    <Box>
                    <Text >
                            <StaticImage  src='../../../images/corporate_images/corporate_img2.png' alt="corporate_img2" />
                        </Text>
                    </Box>
                   
                </Flex>
               
            </Container>
        </Section>
    )
}
