import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
export default function Skilled_trined(props) {
    return (
        <Section  >
            <Container >
                <Flex gap={4} variant="responsive">
                   
                    <Box>
                    <Text >
                            <StaticImage  src='../../../images/corporate_images/corporate_img3.png' alt="corporate_img3" />
                        </Text>
                    </Box>
                    <Box>
                        <Subhead>
                        Skilled/ Trained/ Experienced Candidates for Free of cost

                        </Subhead>
                        <Text>
                        Companies/Industry who are looking for Accountants, Tax specialist, BPO sepcialists, Fianance Executives, Purchase Executives, Stores officer, Commercial Officers, Finance Managers, FP&A etc can easily source CV from our data base at free of cost. Skill-Elevator database of trained resource will improve the hiring speed in the companies and also reduce the cost of hiring as well as trining. Corproates can register at the below link and start using our database.
                        </Text>
                    </Box>
                </Flex>
               
            </Container>
        </Section>
    )
}
