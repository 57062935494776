import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Subhead, PlatformHeading, PlatformSubhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import { corporate_hero, corporate_hero_img } from "../../ui.css"
export default function Corporate_hero(props) {
    return (
        <>
            <Section className={corporate_hero}>
                <Container width="tight" >
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <PlatformHeading    >
                                Corporate Collaboration
                            </PlatformHeading >
                            <PlatformSubhead>
                                The best platform for commerce skilling & job enhancement.
                            </PlatformSubhead>
                        </Box>
                    </Flex>

                </Container>
            </Section>
            <Section> </Section>

            <Container className={corporate_hero_img}>
                <Text >
                    <StaticImage src='../../../images/corporate_images/corporate_banner.png' alt="corporate_banner" />
                </Text>
            </Container>

        </>
    )
}
