import * as React from "react"
import {
  Container,
  Flex,
  FlexList,
  Space,
  VisuallyHidden,
  NavLink,
} from "../ui"
import {
  platformDesktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
} from "../header.css.ts"
import { StaticImage } from "gatsby-plugin-image"
import { activeNavLink, movileActiveNavLink } from "../../css/platform.css"
import { activeLink } from "../header.css"

export default function AssociatesNavList(props) {
  
  const navItems = [{
    id: 1,
    href: "/associates",
    text: "Mentor",
    description: " Mentor",
    navItemType: '',
    isActive: props.navName === 'Mentor' ? true : false
  },
  {
    id: 2,
    href: "/college",
    text: "College",
    description: "College",
    navItemType: '',
    isActive: props.navName === 'College' ? true : false
  },
  {
    id: 3,
    href: "/corporate",
    text: "Corporate",
    description: "Corporate",
    navItemType: '',
    isActive: props.navName === 'Corporate' ? true : false
  }]

  return (
    <header>
      <Container width="container" className={platformDesktopHeaderNavWrapper}>
        <div></div>
        <Container  >
          <Space size={2} />
          <Flex variant="spaceBetween">
          <NavLink to="/">
              <VisuallyHidden>Home</VisuallyHidden>
              <StaticImage
                src="../images/logo.png"
                width="180"
                height="35"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="A Gatsby astronaut"
                style={{ marginBottom: `1.45rem` }}
              />
            </NavLink>
            <nav style={{ marginBottom: 10 }}>
              <FlexList gap={4}>
                {navItems &&
                  navItems.map((navItem) => (
                    <li key={navItem.id}>
                        <NavLink to={navItem.href} > {props.navName === navItem.text ? <span className={activeLink}>{navItem.text}</span> : navItem.text} </NavLink>
                    </li>
                  ))}
              </FlexList>
            </nav>
          </Flex>
        </Container>
      </Container>
      <Container className={mobileHeaderNavWrapper["closed"]}>
        <Space size={2} />
        <Flex variant="spaceBetween">
        <nav style={{ marginBottom: 10 }} right={3}>
          <FlexList gap={4}>
            {navItems &&
              navItems.map((navItem) => (
                <li key={navItem.id} >
                  <NavLink to={navItem.href} > {props.navName === navItem.text ? <span className={activeLink}>{navItem.text}</span> : navItem.text} </NavLink>
                </li>
              ))}
          </FlexList>
          </nav>
        </Flex>
      </Container>
    </header>
  )
}
