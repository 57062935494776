import * as React from "react"
import { Container, Box, Text, Section, Flex, Heading, Subhead } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
export default function Unique_platform(props) {
    return (
        <Section  >
            <Container   >
                <Flex gap={4} variant="responsive">

                    <Box>
                        <Text >
                            <StaticImage src='../../../images/corporate_images/corporate_img1.png' alt="corporate_img1" />
                        </Text>
                    </Box>
                    <Box>
                        <Subhead>
                            Unique Platform to Offer Work Experience
                        </Subhead>
                        <Text>
                            Skill-Elevator platform is a unique Job/Work simulation concept to offer Industry experience before entering to their career. This platform is aiming at improving the standard of graduates in practical side of their learning and connect them with the subjects with objective. It makes them suitable for the job by offerring wide varieties of simulted work modules along with their study. Commerce student upon working in the virtual compnay in our platform, will get to know the thorough work experience as if one has worked in Industry and get themselves equipped to join in any company.
                        </Text>
                    </Box>
                </Flex>

            </Container>
        </Section>
    )
}
